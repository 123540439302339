<template>
  <div class="content">
    <div class="tool-bar top-bar">
      <span class="text-title">© 2024 ueg.cc 沪ICP备2021029308号</span>
      <span class="text-title">友情链接:</span>
      <a class="text-title" href="https://erp.ueg.cc">U-ERP</a>
      <a class="text-title" href="https://blog.xiejijun.cn">blog</a>
    </div>
    <div class="tool-bar">
      <!-- <span class="text-title">联系: xiejijun_05@163.com</span> -->
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        origin: "",
        retsult: ""
      }
    },
    methods: {

    }
  }
</script>
  
<style scoped>
  .top-bar {
    margin-top: 30px;
  }
  .tool-bar {
    text-align: center;
  }
  
  .text-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .text-title {
    color: #666;
    margin-left: 4px;
    font-size: 12px;
  
  }


  </style>
  