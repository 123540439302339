<template>
  <div class="head-logo">
    <img class="logo" alt="在线文本工具" src="../assets/logo.png"> 
  </div>

  <div>
    <ul class="menu-ul">
      <li class="menu-li-m menu-li" :class="index===0?'active':''" @click="homePage">常用工具</li>
      <li class="menu-li-l menu-li" :class="index===1?'active':''" @click="picPage">Base64转图片</li>
      <li class="menu-li-m menu-li" :class="index===2?'active':''" @click="timePage">时间转换</li>
    </ul>
  </div>
</template>


 <script>
    export default {
      props: {
        idx: 0
      },
      data () {
        return {
          index: 0
        }
      },
      created() {
        if (this.idx) {
          this.index = this.idx
        }
      },
      methods: {
        homePage(e) {
          window.location.pathname = '/';
        },
        picPage(e) {
          window.location.pathname = '/pic';
        },
        timePage(e) {
          window.location.pathname = '/time';
        }
      }
    }
  </script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 4px;
}

.head-logo {
  float: right;
}

.logo {
  width: 28px;
  height: 28px;
  float: left;
}
.title {
  margin-left: 40px;
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
  color: #333;
}

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.menu-ul {
  text-align: left;
  width: 1424px;
  font-weight: 500;
  margin: 12px 0 30px 12px;
}
.menu-li {
  float: left;
  margin-right: 8px;
  text-align: center;
}
.menu-li-s {
  width: 60px;
}

.menu-li-m {
  width: 90px;
}

.menu-li-l {
  width: 120px;
}

li.active {
  color: #333;
  border-bottom: 2px solid #E91919;
}

.menu-li:hover{
  color: #E91919;
  cursor: pointer;
}
</style>
