<template>
  <Header @getIndex="getIndex" :idx="idx" />
  <HomePage v-if="pageIdx==='home'"/>
  <ImgVue v-if="pageIdx==='img'" />
  <TimeUtil v-if="pageIdx==='time'" />
  <Link />
</template>

<script>
import Header from './components/Header.vue'
import HomePage from './components/HomePage.vue'
import Link from './components/Link.vue'
import ImgVue from './components/Img.vue'
import TimeUtil from './components/TimeUtil.vue'

export default {
  name: 'App',
  components: {
    Header,
    HomePage,
    Link,
    ImgVue,
    TimeUtil
  },
  data () {
    return {
      pageIdx: "home",
      idx: 0
    }
  },
  created() {
    const path = window.location.pathname;
    if (path === "/pic") {
      this.pageIdx = "img"
      this.idx = 1
    } else if (path === "/time") {
      this.pageIdx = "time"
      this.idx = 2
    } else {
      this.pageIdx = "home"
      this.idx = 0
    }
  },
  methods: {
    getIndex(page) {
      this.pageIdx = page
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 4px;
}

</style>
